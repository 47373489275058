th input {
  font-size: 12px;
  width: 5rem;
}

a {
  color: green;
}

.left {
  align-self: right;
}
.addlinebreak {
  white-space: pre-line;
}

.setting-tr :hover {
  background: rgb(205, 235, 253);
  cursor: auto;
}
.card-center {
  margin-left: auto;
  margin-right: auto;
}
