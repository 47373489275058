.pagination {
  font-size: 10px;
}

.pagination input {
  border: 1px solid #292929;
  color: #292929;
  padding: 0.3rem 0.5rem;
  font: inherit;
  border-radius: 3px;
}
.div-block {
  padding: 0.3rem 0.5rem;
}
